import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { m } from 'framer-motion';
import { useState, useEffect, useMemo, useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
import axios from 'axios';
import Iconify from 'src/components/iconify';
import { RHFAutocomplete } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useForm } from 'react-hook-form';
import AccountGeneral from 'src/sections/account/account-general';

// ----------------------------------------------------------------------

const OPTIONS = [
  // {
  //   label: 'Home',
  //   linkTo: '/',
  // },
  // {
  //   label: 'Profile',
  //   linkTo: paths.dashboard.user.profile,
  // },
  {
    label: 'Profile Settings',
    linkTo: paths.dashboard.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user2 } = useMockedUser();

  const { logout, updateRoles } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const user = JSON.parse(sessionStorage.getItem('user'));
  const token = sessionStorage.getItem('token');
  const popover = usePopover();

  const [selectedSite, setSelectedSite] = useState('');

  // sessionStorage.setItem('selectedSite', selectedSite);

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  const [profilePic, setProfilePic] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getProfilePhoto`,
        {
          params: { id: user.userId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log('Response Data:', response.data);
      // if (response.status === 200) {
      setProfilePic(response.data);
      // } else {
      //   console.error('Failed to fetch logo paths:', response.statusText);
      // }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/users/getProfilePhoto`,
    //       {
    //         params: { id: user.userId },
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );

    //     // console.log('Response Data:', response.data);
    //     // if (response.status === 200) {
    //       setProfilePic(response.data);
    //     // } else {
    //     //   console.error('Failed to fetch logo paths:', response.statusText);
    //     // }
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userId, token]);

  console.log('profile pic account popover:', profilePic);

  // Inside your component
  const [sites, setSites] = useState([]);

  // const options = [
  //   // 'Banglore',
  //   // 'Pune',
  //   // 'Mumbai',
  //   ...sites,
  // ];

  const defaultValues = useMemo(
    () => ({
      currentSites: sites || null,
    }),
    [sites]
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const fetchSites = useCallback(async () => {
    try {
      const response = await axios.post(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/userrole/getbyuser`,
        user, // Pass the user object in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setSites(response.data.map((item) => item.subscrSite.subscrSiteName));
      // setSites(response.data);
      // Filter and extract unique sites based on subscrSiteName
      const uniqueSites = response.data.reduce((accumulator, currentItem) => {
        const existingSite = accumulator.find(
          (site) => site.subscrSite.subscrSiteName === currentItem.subscrSite.subscrSiteName
        );
        if (!existingSite) {
          accumulator.push(currentItem);
        }
        return accumulator;
      }, []);

      setSites(uniqueSites.sort((siteA, siteB) => {
        // Case-insensitive comparison (lowercase both strings)
        const nameA = siteA.subscrSite.subscrSiteName.toLowerCase();
        const nameB = siteB.subscrSite.subscrSiteName.toLowerCase();
        // Return based on alphabetical order
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0; // Equal if no difference
      }));
      // console.log('Sites -----------', sites);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  }, [token, user]);

  useEffect(() => {
    fetchSites();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set the first site as default only when sites array changes
    if (sites.length > 0 && !selectedSite) {
      setSelectedSite(sites[0]); // Set the first site as default if no site is selected
      sessionStorage.setItem('selectedSubscrSite', JSON.stringify(sites[0]));
    }
  }, [sites, selectedSite]);

  // Function to handle site selection
  const handleSiteSelection = (site) => {
    setSelectedSite(site); // Update selectedSite when a different site is selected
    sessionStorage.setItem('selectedSubscrSite', JSON.stringify(site));
  };

  useEffect(() => {
    // Fetch roles based on the selected site and update roles state
    const fetchRoles = async () => {
      try {
        // Fetch roles based on the selected site
        console.log(selectedSite, 'selectedSite');
        const rolesResponse = await axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/roleConfig/rolesOfuser/${
            user.userId
          }/${selectedSite.subscrSite.subscrSiteId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Update roles state
        updateRoles(rolesResponse.data.map((ur) => ur.roleId));
        console.log(rolesResponse.data, "updated roles");
        sessionStorage.setItem('rolesPresent', JSON.stringify(rolesResponse.data));
        
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    // Call the fetchRoles function when the selected site changes
    if (selectedSite) {
      fetchRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, updateRoles]);

  <AccountGeneral handlerForProfile={fetchData} />;

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={profilePic || JSON.parse(sessionStorage.getItem('user'))?.userName}
          alt={JSON.parse(sessionStorage.getItem('user'))?.userName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 300, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {JSON.parse(sessionStorage.getItem('user'))?.userName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {JSON.parse(sessionStorage.getItem('user'))?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <FormProvider methods={methods}>
          <RHFAutocomplete
            name="currentSites"
            label="Current Site"
            placeholder="Current Site"
            options={sites}
            getOptionLabel={(option) => option.subscrSite?.subscrSiteName} // Optional chaining used here
            value={selectedSite}
            onChange={(event, newValue) => handleSiteSelection(newValue)}
            renderOption={(props, option) => (
              <li {...props} key={option.subscrSite?.subscrSiteName}>
                {' '}
                {/* Optional chaining used here */}
                {option.subscrSite?.subscrSiteName || '-'}
              </li>
            )}
          />
        </FormProvider>
        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}
        <MenuItem
          sx={{ p: 1, marginLeft: 1 }}
          onClick={() => {
            handleClickItem(paths.dashboard.user.account);
          }}
        >
          Profile Settings
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
