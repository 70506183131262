import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export default function UserRoleSiteTableRow({ row, selected, onSelectRow }) {
  const { site, role } = row;

  return (
    <TableRow hover selected={selected} style={{ textAlign: 'right' }}>
      <TableCell id={site} sx={{ whiteSpace: 'nowrap' }}>
        {site}
      </TableCell>
      <TableCell id={role} sx={{ whiteSpace: 'nowrap' }}>
        {role}
      </TableCell>
    </TableRow>
  );
}

UserRoleSiteTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectRow: PropTypes.func.isRequired,
};
