export async function cropImage(imageSrc, crop) {
  if (!crop || !crop.width || !crop.height) {
    throw new Error('Invalid crop dimensions');
  }

  const image = new Image();
  image.crossOrigin = 'anonymous'; // To handle CORS issues

  return new Promise((resolve, reject) => {
    image.onload = () => {
      console.log('Image loaded successfully'); // Debug log

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const croppedFile = new File([blob], 'cropped-image.jpg', {
              type: blob.type,
            });
            resolve(croppedFile);
          } else {
            reject(new Error('Failed to crop image'));
          }
        },
        'image/jpeg',
        1 // quality
      );
    };

    image.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    // Set the src after onload and onerror have been set
    image.src = imageSrc;
  });
}
