import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import axios from 'axios';
import { hideScroll } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
import { NavSectionMini } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';

// ----------------------------------------------------------------------

export default function NavMini({sx}) {
  const { user } = useMockedUser();

  const navData = useNavData();
  const [roleArr, setRoleArr] = useState([]);

  const token = sessionStorage.getItem('token');

  // const currentSubSite = JSON.parse(sessionStorage.getItem('selectedSubscrSite'));
  const currentUser = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        await axios
          .get(
            `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/roleConfig/rolesOfuser/${
              currentUser.userId
            }/1`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setRoleArr(res.data.map((ur) => ur.roleId));
          });
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 38,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        {/* <Logo sx={{ width: 130, height: 60, cursor: 'pointer', display: 'inline-flex', ...sx, mx: 'auto', my: 2 }} /> */}

        <NavSectionMini
          data={navData}
          config={{
            currentRole: roleArr,
          }}
        />
      </Stack>
    </Box>
  );
}
NavMini.propTypes = {
  sx: PropTypes.object,
};

// import { useEffect, useState } from 'react';
// // @mui
// import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// // theme
// import axios from 'axios';
// import { hideScroll } from 'src/theme/css';
// // hooks
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// // components
// import Logo from 'src/components/logo';
// import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
// import { NavSectionMini } from 'src/components/nav-section';
// //
// import { NAV } from '../config-layout';
// import { useNavData } from './config-navigation';
// import { NavToggleButton } from '../_common';

// // ----------------------------------------------------------------------

// export default function NavMini() {
//   const { user } = useMockedUser();

//   const navData = useNavData();

//   const [roleArr, setRoleArr] = useState([]);

//   const token = sessionStorage.getItem('token');

//   // const currentSubSite = JSON.parse(sessionStorage.getItem('selectedSubscrSite'));
//   const currentUser = JSON.parse(sessionStorage.getItem('user'));

//   useEffect(() => {
//     const fetchRoles = async () => {
//       try {
//         await axios
//           .get(
//             `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/roleConfig/rolesOfuser/${
//               currentUser.userId
//             }/1`,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           )
//           .then((res) => {
//             setRoleArr(res.data.map((ur) => ur.roleId));
//           });
//       } catch (error) {
//         console.error('Error fetching roles:', error);
//       }
//     };
//     fetchRoles();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return (
//     <Box
//       component="nav"
//       sx={{
//         flexShrink: { lg: 0 },
//         width: { lg: NAV.W_MINI },
//         height: 'calc(100% - 80px)',
//         position: 'fixed', // Fix the position to avoid overflow issues
//       }}
//       // sx={{
//       //   flexShrink: { lg: 0 },
//       //   width: { lg: NAV.W_MINI },
//       // }}
//     >
//       <NavToggleButton
//         sx={{
//           mt: '40px',
//           top: 22,
//           left: NAV.W_MINI - 12,
//         }}
//       />

//       <Stack
//        sx={{
//         mt: '80px', // Adjust top margin
//         pb: 2,
//         height: 'calc(100% - 80px)', // Adjust height to account for top margin
//         width: NAV.W_MINI,
//         borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
//         ...hideScroll.x,
//       }}
//         // sx={{
//         //   pb: 2,
//         //   height: 1,
//         //   position: 'fixed',
//         //   width: NAV.W_MINI,
//         //   borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
//         //   ...hideScroll.x,
//         // }}
//       >
//         <NavSectionMini
//           data={navData}
//           config={{
//             currentRole: roleArr,
//           }}
//         />
//       </Stack>
//     </Box>
//   );
// }
