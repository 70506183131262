// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const test_id = 2;

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // tenant reg
  tenantreg: '/tenant-reg',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`,
      changePassword: `${ROOTS.AUTH}/jwt/change-password`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
      newPassword: `${ROOTS.AUTH}/jwt/new-password`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    // calendar: `${ROOTS.DASHBOARD}/calendar`,
    // calendarform: `${ROOTS.DASHBOARD}/calendarform`,
    // calenderUpdate: (arg) => `${ROOTS.DASHBOARD}/calenderUpdate/${arg}`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
      // Tag Dashboard
      tag: `${ROOTS.DASHBOARD}/tag`,
    },
    talentAcquisition: {
      root: `${ROOTS.DASHBOARD}/talentAcquisition`,
      NewRequirement: `${ROOTS.DASHBOARD}/talentAcquisition/NewRequirement`,
      EditReq: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/EditReq/${id}`,
      DetailsReq: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/DetailsReq/${id}`,
      ReuseReq: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/ReuseReq/${id}`,
      RequirementList: `${ROOTS.DASHBOARD}/talentAcquisition/RequirementList`,
      CanReqTagList: `${ROOTS.DASHBOARD}/talentAcquisition/CanReqTagList`,
      CandidateStatusViewList: (id) =>
        `${ROOTS.DASHBOARD}/talentAcquisition/CandidateStatusViewList/${id}`,
      CanReqManagerList: `${ROOTS.DASHBOARD}/talentAcquisition/CanReqManagerList`,
      CandidateStatusViewMgrList: (id) =>
        `${ROOTS.DASHBOARD}/talentAcquisition/CandidateStatusViewMgrList/${id}`,
      OfferLetter: `${ROOTS.DASHBOARD}/talentAcquisition/OfferLetter`,
      OfferedCandidates: `${ROOTS.DASHBOARD}/talentAcquisition/OfferedCandidates`,
      SelectedCandidates: `${ROOTS.DASHBOARD}/talentAcquisition/SelectedCandidates`,
      offerrejected: `${ROOTS.DASHBOARD}/talentAcquisition/offerrejected`,
      offerfetch: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/${id}/offerfetch`,
      reoffer: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/${id}/reoffer`,
      requirementfetch: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/${id}/requirementfetch`,
      offeredit: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/${id}/offeredit`,
      interviewfetch: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/${id}/interviewfetch`,
      calendar: `${ROOTS.DASHBOARD}/talentAcquisition/calendar`,
      calendarform: `${ROOTS.DASHBOARD}/talentAcquisition/calendarform`,
      calenderUpdate: (arg) => `${ROOTS.DASHBOARD}/talentAcquisition/calenderUpdate/${arg}`,
      InterviewFeedbackView: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/InterviewFeedbackView/${id}`,
      ListInterview: `${ROOTS.DASHBOARD}/talentAcquisition/ListInterview`,
      InterviewResult: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/InterviewResult/${id}`,
      InterviewResulEdit: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/InterviewResultEdit/${id}`,
      InterviewResulDetail: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/InterviewResultView/${id}`,

      master: {
        root: `${ROOTS.DASHBOARD}/talentAcquisition/master`,
        NewJd: `${ROOTS.DASHBOARD}/talentAcquisition/master/NewJd`,
        PastJd: `${ROOTS.DASHBOARD}/talentAcquisition/master/PastJd`,
        EditJd: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/master/EditJd/${id}`,
        ReuseJd: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/master/ReuseJd/${id}`,
        DetailsJd: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/master/DetailsJd/${id}`,
        // ListEmployee: `${ROOTS.DASHBOARD}/talentAcquisition/master/ListEmployee`,
        // NewEmployee: `${ROOTS.DASHBOARD}/talentAcquisition/master/NewEmployee`,
        // EditEmployee: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/master/EditEmployee/${id}`,
        NewCandidate: `${ROOTS.DASHBOARD}/talentAcquisition/master/NewCandidate`,
        ListCandidate: `${ROOTS.DASHBOARD}/talentAcquisition/master/ListCandidate`,
        // ViewEmployee: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/master/ViewEmployee/${id}`,
        EditCandidate: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/master/EditCandidate/${id}`,
        ViewCandidate: (id) => `${ROOTS.DASHBOARD}/talentAcquisition/master/ViewCandidate/${id}`,
      },
      report: `${ROOTS.DASHBOARD}/talentAcquisition/report`,
    },

    humanResources : {
      root: `${ROOTS.DASHBOARD}/humanResources`,
      ListEmployee: `${ROOTS.DASHBOARD}/humanResources/ListEmployee`,
      NewEmployee: `${ROOTS.DASHBOARD}/humanResources/NewEmployee`,
      EditEmployee: (id) => `${ROOTS.DASHBOARD}/humanResources/EditEmployee/${id}`,
      ViewEmployee: (id) => `${ROOTS.DASHBOARD}/humanResources/ViewEmployee/${id}`,
    },

    interview: {
      root: `${ROOTS.DASHBOARD}/interview`,
      
      
    },
    assets: {
      root: `${ROOTS.DASHBOARD}/assets`,
      AssetTrackerList: `${ROOTS.DASHBOARD}/assets/AssetTrackerList`,
      AssetTrackerAdminList: `${ROOTS.DASHBOARD}/assets/AssetTrackerAdminList`,
      ReservedAssets: `${ROOTS.DASHBOARD}/assets/reservedassets`,
    },
    admin: {
      root: `${ROOTS.DASHBOARD}/admin`,
      
    },
    preferences: {
      root: `${ROOTS.DASHBOARD}/preferences`,
      TenantConfig: `${ROOTS.DASHBOARD}/preferences/tenantconfig`,
    },
    procurement: {
      root: `${ROOTS.DASHBOARD}/procurement`,
      PurchaseRequisition: `${ROOTS.DASHBOARD}/procurement/purchasereq`,
    },

    superAdmin: {
      root: `${ROOTS.DASHBOARD}/super-admin`,
      rolePermission: `${ROOTS.DASHBOARD}/super-admin/role-permission`,
      new: `${ROOTS.DASHBOARD}/super-admin/role-permission/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/super-admin/role-permission/editRoleperm/${id}`,
      view: (id) => `${ROOTS.DASHBOARD}/super-admin/role-permission/detailRoleperm/${id}`,
      usermst: `${ROOTS.DASHBOARD}/super-admin/UserMst`,
      userform: `${ROOTS.DASHBOARD}/super-admin/UserForm`,
      UserRoleAssign: `${ROOTS.DASHBOARD}/super-admin/UserRoleAssign`,
    },
    ManagerApproval: {
      root: `${ROOTS.DASHBOARD}/ManagerApproval`,
      CanReqApproval: `${ROOTS.DASHBOARD}/ManagerApproval/CanReqApproval`,
      MaterialReqApproval: `${ROOTS.DASHBOARD}/ManagerApproval/MaterialReqApproval`,
      PurReqApproval: `${ROOTS.DASHBOARD}/ManagerApproval/PurReqApproval`,
      PendingCanReq: `${ROOTS.DASHBOARD}/ManagerApproval/PendingCanReq`,
      ApprovedCanReq: `${ROOTS.DASHBOARD}/ManagerApproval/ApprovedCanReq`,
      RejectedCanReq: `${ROOTS.DASHBOARD}/ManagerApproval/RejectedCanReq`,
    },

    inventory: {
      root: `${ROOTS.DASHBOARD}/inventory`,
      req: `${ROOTS.DASHBOARD}/inventory/req`,
      ItemRequisition: `${ROOTS.DASHBOARD}/inventory/ItemRequisition`,
      InvTransaction: (id, type) => `${ROOTS.DASHBOARD}/inventory/InvTransaction/${id}/${type}`,
      InvTransactionList: `${ROOTS.DASHBOARD}/inventory/InvTransactionList`,
      // AssetTrackerList: `${ROOTS.DASHBOARD}/inventory/AssetTrackerList`,
      InvOnHandList: `${ROOTS.DASHBOARD}/inventory/InvOnHandList`,
      PhysicalInventoryList: (id) => `${ROOTS.DASHBOARD}/inventory/PhysicalInventoryList/${id}`,
      AllPhysicalInventoryList: `${ROOTS.DASHBOARD}/inventory/AllPhysicalInventoryList`,
      // AssetTrackerAdminList: `${ROOTS.DASHBOARD}/inventory/AssetTrackerAdminList`,
      InspectionParameterMasterList: `${ROOTS.DASHBOARD}/inventory/InspectionParameterMasterList`,
      InspectionList: `${ROOTS.DASHBOARD}/inventory/InspectionList`,
      
      AllItemReqTag: `${ROOTS.DASHBOARD}/inventory/AllItemReqTag`,
      InvComparisonList: (id) => `${ROOTS.DASHBOARD}/inventory/invcomparison/${id}`,

      master: {
        root: `${ROOTS.DASHBOARD}/inventory/master`,
        ItemList: `${ROOTS.DASHBOARD}/inventory/master/list`,
        NewItem: `${ROOTS.DASHBOARD}/inventory/master/NewItem`,
        details: (id) => `${ROOTS.DASHBOARD}/inventory/master/${id}`,
        EditItem: (id) => `${ROOTS.DASHBOARD}/inventory/master/EditItem/${id}`,
        AssetList: `${ROOTS.DASHBOARD}/inventory/master/AssetList`,
      },
      report: `${ROOTS.DASHBOARD}/inventory/report`,
    },

    masters: {
      root: `${ROOTS.DASHBOARD}/masters`,

      globalMasters: {
        root: `${ROOTS.DASHBOARD}/masters/globalMasters`,
        AddressPurpose: `${ROOTS.DASHBOARD}/masters/globalMasters/AddressPurpose`,
        BankAccountType: `${ROOTS.DASHBOARD}/masters/globalMasters/BankAccountType`,
        BankName: `${ROOTS.DASHBOARD}/masters/globalMasters/BankName`,
        BloodGroup: `${ROOTS.DASHBOARD}/masters/globalMasters/BloodGroup`,
        BusinessUnit: `${ROOTS.DASHBOARD}/masters/globalMasters/BusinessUnit`,
        CertificateType: `${ROOTS.DASHBOARD}/masters/globalMasters/CertificateType`,
        Certificates: `${ROOTS.DASHBOARD}/masters/globalMasters/Certificates`,
        ContractType: `${ROOTS.DASHBOARD}/masters/globalMasters/ContractType`,
        DeliveryUnit: `${ROOTS.DASHBOARD}/masters/globalMasters/DeliveryUnit`,
        Department: `${ROOTS.DASHBOARD}/masters/globalMasters/Department`,
        Designation: `${ROOTS.DASHBOARD}/masters/globalMasters/Designation`,
        Domain: `${ROOTS.DASHBOARD}/masters/globalMasters/Domain`,
        EmployementType: `${ROOTS.DASHBOARD}/masters/globalMasters/EmployementType`,
        Gender: `${ROOTS.DASHBOARD}/masters/globalMasters/Gender`,
        Good: `${ROOTS.DASHBOARD}/masters/globalMasters/Good`,
        HsnSac: `${ROOTS.DASHBOARD}/masters/globalMasters/HsnSac`,
        Institute: `${ROOTS.DASHBOARD}/masters/globalMasters/Institute`,
        InterviewLevel: `${ROOTS.DASHBOARD}/masters/globalMasters/InterviewLevel`,
        InterviewStatus: `${ROOTS.DASHBOARD}/masters/globalMasters/InterviewStatus`,
        ItemCategoryMst: `${ROOTS.DASHBOARD}/masters/globalMasters/ItemCategoryMst`,
        ItemCondition: `${ROOTS.DASHBOARD}/masters/globalMasters/ItemCondition`,
        ItemType: `${ROOTS.DASHBOARD}/masters/globalMasters/ItemType`,
        Language: `${ROOTS.DASHBOARD}/masters/globalMasters/Language`,
        MaritalStatus: `${ROOTS.DASHBOARD}/masters/globalMasters/MaritalStatus`,
        MeetingPlatform: `${ROOTS.DASHBOARD}/masters/globalMasters/MeetingPlatform`,
        NameTitle: `${ROOTS.DASHBOARD}/masters/globalMasters/NameTitle`,
        OfferWithdrawnReason: `${ROOTS.DASHBOARD}/masters/globalMasters/OfferWithdrawnReason`,
        OrganizationType: `${ROOTS.DASHBOARD}/masters/globalMasters/OrganizationType`,
        PositionLevel: `${ROOTS.DASHBOARD}/masters/globalMasters/PositionLevel`,
        Practice: `${ROOTS.DASHBOARD}/masters/globalMasters/Practice`,
        ProjectStatus: `${ROOTS.DASHBOARD}/masters/globalMasters/ProjectStatus`,
        PTList: `${ROOTS.DASHBOARD}/masters/globalMasters/PTList`,
        Qualification: `${ROOTS.DASHBOARD}/masters/globalMasters/Qualification`,
        QualificationStreams: `${ROOTS.DASHBOARD}/masters/globalMasters/QualificationStreams`,
        QualificationType: `${ROOTS.DASHBOARD}/masters/globalMasters/QualificationType`,
        Questions: `${ROOTS.DASHBOARD}/masters/globalMasters/Questions`,
        Reason: `${ROOTS.DASHBOARD}/masters/globalMasters/Reason`,
        Relationship: `${ROOTS.DASHBOARD}/masters/globalMasters/Relationship`,
        Religion: `${ROOTS.DASHBOARD}/masters/globalMasters/Religion`,
        ReqStatus: `${ROOTS.DASHBOARD}/masters/globalMasters/ReqStatus`,
        RevenueType: `${ROOTS.DASHBOARD}/masters/globalMasters/RevenueType`,
        Role: `${ROOTS.DASHBOARD}/masters/globalMasters/Role`,
        Service: `${ROOTS.DASHBOARD}/masters/globalMasters/Service`,
        ServiceCategory: `${ROOTS.DASHBOARD}/masters/globalMasters/ServiceCategory`,
        SkillList: `${ROOTS.DASHBOARD}/masters/globalMasters/SkillList`,
        SkillCategory: `${ROOTS.DASHBOARD}/masters/globalMasters/SkillCategory`,
        SourceOfHire: `${ROOTS.DASHBOARD}/masters/globalMasters/SourceOfHire`,
        TDSSection: `${ROOTS.DASHBOARD}/masters/globalMasters/TDS`,
        TransactionType: `${ROOTS.DASHBOARD}/masters/globalMasters/TransactionType`,
        University: `${ROOTS.DASHBOARD}/masters/globalMasters/University`,
        UOM: `${ROOTS.DASHBOARD}/masters/globalMasters/UOM`,
        VisaName: `${ROOTS.DASHBOARD}/masters/globalMasters/VisaName`,
        VisaType: `${ROOTS.DASHBOARD}/masters/globalMasters/VisaType`,
        WorkMode: `${ROOTS.DASHBOARD}/masters/globalMasters/WorkMode`,
      },

      localMasters: {
        root: `${ROOTS.DASHBOARD}/masters/localMasters`,
        DeclineReason: `${ROOTS.DASHBOARD}/masters/localMasters/DeclineReason`,
        OfferWithdrawReason: `${ROOTS.DASHBOARD}/masters/localMasters/OfferWithdrawReason`,
        EmpGrade: `${ROOTS.DASHBOARD}/masters/localMasters/EmpGrade`,
        InterviewCancelReason: `${ROOTS.DASHBOARD}/masters/localMasters/InterviewCancelReason`,
        InspectionParameter: `${ROOTS.DASHBOARD}/masters/localMasters/InspectionParameter`,
        Modules: `${ROOTS.DASHBOARD}/masters/localMasters/Modules`,
        NegativeFeedback: `${ROOTS.DASHBOARD}/masters/localMasters/NegativeFeedback`,
        Recommendation: `${ROOTS.DASHBOARD}/masters/localMasters/Recommendation`,
        Reports: `${ROOTS.DASHBOARD}/masters/localMasters/Reports`,
        RoleLevel: `${ROOTS.DASHBOARD}/masters/localMasters/RoleLevel`,
        Screens: `${ROOTS.DASHBOARD}/masters/localMasters/Screens`,
        Service: `${ROOTS.DASHBOARD}/masters/localMasters/Service`,
        ServicePlan: `${ROOTS.DASHBOARD}/masters/localMasters/ServicePlan`,
        SubInventory: `${ROOTS.DASHBOARD}/masters/localMasters/SubInventory`,
      },
      ListClient: `${ROOTS.DASHBOARD}/masters/ListClient`,
      ClientMst: `${ROOTS.DASHBOARD}/masters/ClientMst`,
      EditClient: (id) => `${ROOTS.DASHBOARD}/masters/EditClient/${id}`,
      DetailClient: (id) => `${ROOTS.DASHBOARD}/masters/DetailClient/${id}`,
      ListVendor: `${ROOTS.DASHBOARD}/masters/ListVendor`,
      VendorMst: `${ROOTS.DASHBOARD}/masters/VendorMst`,
      EditVendor: (id) => `${ROOTS.DASHBOARD}/masters/EditVendor/${id}`,
      DetailVendor: (id) => `${ROOTS.DASHBOARD}/masters/DetailVendor/${id}`,
    },

    // master: {
    //   root: `${ROOTS.DASHBOARD}/master`,
    //   usermst: `${ROOTS.DASHBOARD}/master/UserMst`,
    //   userform: `${ROOTS.DASHBOARD}/master/UserForm`,
    //   AddressPurpose: `${ROOTS.DASHBOARD}/master/AddressPurpose`,
    //   BankAccountType: `${ROOTS.DASHBOARD}/master/BankAccountType`,
    //   BankName: `${ROOTS.DASHBOARD}/master/BankName`,
    //   BloodGroup: `${ROOTS.DASHBOARD}/master/BloodGroup`,
    //   BusinessUnit: `${ROOTS.DASHBOARD}/master/BusinessUnit`,
    //   CertificateType: `${ROOTS.DASHBOARD}/master/CertificateType`,
    //   ContractType: `${ROOTS.DASHBOARD}/master/ContractType`,
    //   DeclineReason: `${ROOTS.DASHBOARD}/master/DeclineReason`,
    //   DeliveryUnit: `${ROOTS.DASHBOARD}/master/DeliveryUnit`,
    //   Department: `${ROOTS.DASHBOARD}/master/Department`,
    //   Designation: `${ROOTS.DASHBOARD}/master/Designation`,
    //   Domain: `${ROOTS.DASHBOARD}/master/Domain`,
    //   EmpGrade: `${ROOTS.DASHBOARD}/master/EmpGrade`,
    //   EmployementType: `${ROOTS.DASHBOARD}/master/EmployementType`,
    //   Gender: `${ROOTS.DASHBOARD}/master/Gender`,
    //   HsnSac: `${ROOTS.DASHBOARD}/master/HsnSac`,
    //   InterviewLevel: `${ROOTS.DASHBOARD}/master/InterviewLevel`,
    //   InterviewCancelReason: `${ROOTS.DASHBOARD}/master/InterviewCancelReason`,
    //   ItemCategoryMst: `${ROOTS.DASHBOARD}/master/ItemCategoryMst`,
    //   ItemCondition: `${ROOTS.DASHBOARD}/master/ItemCondition`,
    //   ItemType: `${ROOTS.DASHBOARD}/master/ItemType`,
    //   Language: `${ROOTS.DASHBOARD}/master/Language`,
    //   SkillList: `${ROOTS.DASHBOARD}/master/list`,
    //   MaritalStatus: `${ROOTS.DASHBOARD}/master/maritalstatus`,
    //   MeetingPlatform: `${ROOTS.DASHBOARD}/master/MeetingPlatform`,
    //   NameTitle: `${ROOTS.DASHBOARD}/master/NameTitle`,
    //   OrganizationType: `${ROOTS.DASHBOARD}/master/OrganizationType`,
    //   PositionLevel: `${ROOTS.DASHBOARD}/master/PositionLevel`,
    //   Practice: `${ROOTS.DASHBOARD}/master/Practice`,
    //   ProjectStatus: `${ROOTS.DASHBOARD}/master/ProjectStatus`,
    //   PTList: `${ROOTS.DASHBOARD}/master/PTList`,
    //   Qualification: `${ROOTS.DASHBOARD}/master/Qualification`,
    //   QualificationStreams: `${ROOTS.DASHBOARD}/master/QualificationStreams`,
    //   Questions: `${ROOTS.DASHBOARD}/master/Questions`,
    //   Relationship: `${ROOTS.DASHBOARD}/master/Relationship`,
    //   Religion: `${ROOTS.DASHBOARD}/master/Religion`,
    //   ReqStatus: `${ROOTS.DASHBOARD}/master/ReqStatus`,
    //   RevenueType: `${ROOTS.DASHBOARD}/master/RevenueType`,
    //   Role: `${ROOTS.DASHBOARD}/master/Role`,
    //   RoleLevel: `${ROOTS.DASHBOARD}/master/RoleLevel`,
    //   ServiceCategory: `${ROOTS.DASHBOARD}/master/ServiceCategory`,
    //   ServicePlan: `${ROOTS.DASHBOARD}/master/ServicePlan`,
    //   SkillCategory: `${ROOTS.DASHBOARD}/master/SkillCategory`,
    //   SourceOfHire: `${ROOTS.DASHBOARD}/master/SourceOfHire`,
    //   SubInventory: `${ROOTS.DASHBOARD}/master/SubInv`,
    //   TDSSection: `${ROOTS.DASHBOARD}/master/TDS`,
    //   TransactionType: `${ROOTS.DASHBOARD}/master/TransactionType`,
    //   University: `${ROOTS.DASHBOARD}/master/University`,
    //   UOM: `${ROOTS.DASHBOARD}/master/UOM`,
    //   VisaName: `${ROOTS.DASHBOARD}/master/VisaName`,
    //   VisaType: `${ROOTS.DASHBOARD}/master/VisaType`,
    //   WorkMode: `${ROOTS.DASHBOARD}/master/WorkMode`,
    //   Certificates: `${ROOTS.DASHBOARD}/master/Certificates`,
    //   Institute: `${ROOTS.DASHBOARD}/master/Institute`,
    //   InterviewStatus: `${ROOTS.DASHBOARD}/master/InterviewStatus`,
    //   Modules: `${ROOTS.DASHBOARD}/master/Modules`,
    //   NegativeFeedback: `${ROOTS.DASHBOARD}/master/NegativeFeedback`,
    //   QualificationType: `${ROOTS.DASHBOARD}/master/QualificationType`,
    //   Reason: `${ROOTS.DASHBOARD}/master/Reason`,
    //   Recommendation: `${ROOTS.DASHBOARD}/master/Recommendation`,
    //   Reports: `${ROOTS.DASHBOARD}/master/Reports`,
    //   Screens: `${ROOTS.DASHBOARD}/master/Screens`,
    //   Good:`${ROOTS.DASHBOARD}/master/Good`,
    //   Service:`${ROOTS.DASHBOARD}/master/Service`,

    // },

    // recruitment: {
    //   root: `${ROOTS.DASHBOARD}/recruitment`,
    //   NewCandidate: `${ROOTS.DASHBOARD}/recruitment/NewCandidate`,
    //   ListCandidate: `${ROOTS.DASHBOARD}/recruitment/ListCandidate`,
    //   EditCandidate: (id) => `${ROOTS.DASHBOARD}/recruitment/EditCandidate/${id}`,
    //   ViewCandidate: (id) => `${ROOTS.DASHBOARD}/recruitment/ViewCandidate/${id}`,
    //   CanReqTagList: `${ROOTS.DASHBOARD}/recruitment/CanReqTagList`,
    //   CandidateStatusViewList: (id) => `${ROOTS.DASHBOARD}/recruitment/CandidateStatusViewList/${id}`,
    //   CanReqManagerList: `${ROOTS.DASHBOARD}/recruitment/CanReqManagerList`,
    //   CandidateStatusViewMgrList: (id) =>
    //     `${ROOTS.DASHBOARD}/recruitment/CandidateStatusViewMgrList/${id}`,
    //   OfferLetter: `${ROOTS.DASHBOARD}/recruitment/OfferLetter`,
    //   OfferedCandidates: `${ROOTS.DASHBOARD}/recruitment/OfferedCandidates`,
    //   offerrejected: `${ROOTS.DASHBOARD}/recruitment/offerrejected`,
    //   offerfetch: (id) => `${ROOTS.DASHBOARD}/recruitment/${id}/offerfetch`,
    //   offeredit: (id) => `${ROOTS.DASHBOARD}/recruitment/${id}/offeredit`,
    // },
    tenantconfiguration: {
      root: `${ROOTS.DASHBOARD}/tenantconfiguration`,
      TenantConfig: `${ROOTS.DASHBOARD}/tenantconfig`,
    },
    // jd: {
    //   root: `${ROOTS.DASHBOARD}/jd`,
    //   NewJd: `${ROOTS.DASHBOARD}/jd/NewJd`,
    //   PastJd: `${ROOTS.DASHBOARD}/jd/PastJd`,
    //   EditJd: (id) => `${ROOTS.DASHBOARD}/jd/EditJd/${id}`,
    //   ReuseJd: (id) => `${ROOTS.DASHBOARD}/jd/ReuseJd/${id}`,
    //   bco: (bc) => `${ROOTS.DASHBOARD}/jd/${bc}`,
    // },
    // ManagerApproval : {
    //   CanReqApproval: `${ROOTS.DASHBOARD}/ManagerApproval/CanReqApproval`,
    //   MaterialReqApproval: `${ROOTS.DASHBOARD}/ManagerApproval/MaterialReqApproval`,
    // },
    // CandidateRequirement: {
    //   root: `${ROOTS.DASHBOARD}/CandidateRequirement/NewRequirement`,
    //   NewRequirement: `${ROOTS.DASHBOARD}/CandidateRequirement/NewRequirement`,
    //   EditReq: (id) => `${ROOTS.DASHBOARD}/CandidateRequirement/EditReq/${id}`,
    //   ReuseReq: (id) => `${ROOTS.DASHBOARD}/CandidateRequirement/ReuseReq/${id}`,
    //   RequirementList: `${ROOTS.DASHBOARD}/CandidateRequirement/RequirementList`,
    // },
    tag: {
      root: `${ROOTS.DASHBOARD}/taginterview`,
      // InterviewFeedbackView: (id) => `${ROOTS.DASHBOARD}/taginterview/InterviewFeedbackView/${id}`,
    },
    // interview: {
    //   root: `${ROOTS.DASHBOARD}/interview`,
    //   ListInterview: `${ROOTS.DASHBOARD}/interview/ListInterview`,
    //   InterviewResult: (id) => `${ROOTS.DASHBOARD}/interview/InterviewResult/${id}`,
    //   InterviewResulEdit: (id) => `${ROOTS.DASHBOARD}/interview/edit/${id}`
    // },
    // employee: {
    //   root: `${ROOTS.DASHBOARD}/employee`,
    //   ListEmployee: `${ROOTS.DASHBOARD}/employee/ListEmployee`,
    //   NewEmployee: `${ROOTS.DASHBOARD}/employee/NewEmployee`,
    //   EditEmployee: (id) => `${ROOTS.DASHBOARD}/employee/EditEmployee/${id}`,
    // },
    client: {
      root: `${ROOTS.DASHBOARD}/client`,
     
    },
    project: {
      roots: `${ROOTS.DASHBOARD}/project`,
      projectlist: `${ROOTS.DASHBOARD}/project/projectlist`,
      projectform: `${ROOTS.DASHBOARD}/project/projectform`,
      edit: (id) => `${ROOTS.DASHBOARD}/project/${id}/edit`,
      view: (id) => `${ROOTS.DASHBOARD}/project/${id}/view`,
      projectallocationform: `${ROOTS.DASHBOARD}/project/projectallocationform`,
    },
    vendor: {
      root: `${ROOTS.DASHBOARD}/vendor`,
      
    },

    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      NewItem: `${ROOTS.DASHBOARD}/product/NewItem`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      EditItem: (id) => `${ROOTS.DASHBOARD}/product/EditItem/${id}`,
      req: `${ROOTS.DASHBOARD}/product/req`,
      ItemRequisition: `${ROOTS.DASHBOARD}/product/ItemRequisition`,
      InvTransaction: `${ROOTS.DASHBOARD}/product/InvTransaction`,
      InvTransactionList: `${ROOTS.DASHBOARD}/product/InvTransactionList`,
      AssetList: `${ROOTS.DASHBOARD}/product/AssetList`,
      AssetTrackerList: `${ROOTS.DASHBOARD}/product/AssetTrackerList`,
      InvOnHandList: `${ROOTS.DASHBOARD}/product/InvOnHandList`,
      PhysicalInventoryList: (id) => `${ROOTS.DASHBOARD}/product/PhysicalInventoryList/${id}`,
      AllPhysicalInventoryList: `${ROOTS.DASHBOARD}/product/AllPhysicalInventoryList`,
      AssetTrackerAdminList: `${ROOTS.DASHBOARD}/product/AssetTrackerAdminList`,
      InspectionParameterMasterList: `${ROOTS.DASHBOARD}/product/InspectionParameterMasterList`,
      InspectionList: `${ROOTS.DASHBOARD}/product/InspectionList`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
