// import PropTypes from 'prop-types';
// import { FormProvider as Form } from 'react-hook-form';

// // ----------------------------------------------------------------------

// export default function FormProvider({ children, onSubmit, methods }) {
//   return (
//     <Form {...methods}>
//       <form onSubmit={onSubmit}>{children}</form>
//     </Form>
//   );
// }

// FormProvider.propTypes = {
//   children: PropTypes.node,
//   methods: PropTypes.object,
//   onSubmit: PropTypes.func,
// };

import PropTypes from 'prop-types';
import { FormProvider as Form } from 'react-hook-form';
import './FormProvider.css'; // Import your CSS file


export default function FormProvider({ children, onSubmit, methods, disabled }) {
  return (
    <Form {...methods}>
      <fieldset disabled={disabled} style={{ border: 'none' }}>
        <form onSubmit={onSubmit}>{children}</form>
      </fieldset>
    </Form>
  );
}

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};
